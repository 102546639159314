import modalStyle from "styles/components/Modal.module.scss";

import Close from "images/ui-plus-close.svg";

type Props = {
    setShowModal: any;
    gallery: any;
    activeIndex?: number;
};

// Import Swiper React components
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y
} from 'swiper/modules';

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// ENV Variables
const aws_bucket: string = process.env.NEXT_PUBLIC_AWS_BUCKET as string;

function GalleryModal({ setShowModal, gallery, activeIndex }: Props) {

    return (
        <div className={`${modalStyle.dialogLayer} ${modalStyle.fgdGalleryModal}`}>
            <div className={`${modalStyle.dialogLayerInner}`}>
                <div className={`${modalStyle.background}`} onClick={() => {
                    setShowModal(false);
                }}></div>
                <div role={'dialog'} id={'folder-dialog'} aria-modal={true} className={`${modalStyle.dialogBox} ${modalStyle.mediaContainer}`}>
                    <button className={`${modalStyle.closeBtn}`} onClick={() => {
                        setShowModal(false);
                    }}>
                        <span>Close</span>
                        <Close />
                    </button>
                    <div className={modalStyle.mediaContent}>
                        <div className={modalStyle.galleryContainer}>
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                navigation
                                spaceBetween={25}
                                initialSlide={activeIndex || 0}
                            >
                                {gallery && gallery.map((item: any, index: any) => {
									const cleanURL: string = 'https://' + aws_bucket + '.s3.amazonaws.com/' + item;
                                    return (
                                        <SwiperSlide key={`gallery-slide-${index}`}>
                                            <figure>
                                                <img src={cleanURL} alt={cleanURL} />
                                            </figure>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryModal;