import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { processBannerImage, processVideoUrl } from "helpers/functions";
import { createPortal } from "react-dom";

// Components
import VideoModal from "components/creative-effectiveness/VideoModal";

// Styles
import styles from "styles/pages/feel-good-design/FeelGoodDesign.module.scss";

type Props = {
    embed?: string;
    title?: string;
    thumbnail?: string;
    field_size?: string;
    has_border?: boolean;
    thumbnail_border?: boolean;
};

const Modal = (props: any) => {
    const [container] = React.useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement('div');
    });

    React.useEffect(() => {
        container.classList.add('root-protal')
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return (
        <>
            {createPortal(
                props.children,
                container,
            )}
        </>
    );
}

const VideoContent = ({ embed, title, thumbnail, field_size, has_border, thumbnail_border }: Props) => {
    const [showModal, setShowModal] = useState(false);
    let videoUrl: any = processVideoUrl('MKMSkUcpBkg', 'youtube');
    let imageUrl: any = thumbnail;
    let ext: any = '';
    if (imageUrl && !imageUrl.includes('https://')) {
        imageUrl = processBannerImage(imageUrl, true);
    }
    if (thumbnail) {
        ext = thumbnail.split('.').pop();
    }

    return (
        <div className={`${styles.videoContent} ${styles.content}`} data-size={field_size ? field_size : '100'}>
            {ext === 'mp4' ? (
                <div className={styles.videoContainer}>
                    <video width={'835'} height={'469'} muted autoPlay controls>
                        <source src={processBannerImage(imageUrl, true)} type={`video/${ext}`} />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ) : (
                <>
                    <div className={`${styles.thumbnailContainer} ${thumbnail_border ? 'hasBorder' : ''} ${has_border ? styles.hasBorder : ''}`}>
                        <button onClick={() => {
                            setShowModal(true);
                        }} className={styles.playButton}>View Now</button>
                        <img
                            src={imageUrl ? imageUrl : '/images/null-placeholder.png'}
                            alt={title ? title : ''}
                        />
                    </div>
                    {showModal && (
                        <Modal>
                            <VideoModal
                                title={title ? title : ''}
                                setShowModal={setShowModal}
                                embed={embed}
                            />
                        </Modal>
                    )}
                </>
            )}
        </div>
    )
}

export default VideoContent; 