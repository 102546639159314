import React, { useState, useEffect, useRef } from "react";
import parse, { DOMNode, domToReact } from "html-react-parser";
import Image from "next/image";
import { createPortal } from "react-dom";

// Components
import VideoModal from "components/creative-effectiveness/VideoModal";

// Functions
import { processS3Image, processBannerImage } from "helpers/functions";

// Styles
import styles from "styles/pages/feel-good-design/FeelGoodDesign.module.scss";
import modalStyle from "styles/components/Modal.module.scss";

// Images
import LinkArrow from "images/ui-download-arrow.svg";
import Close from "images/ui-plus-close.svg";

type Props = {
    content?: any;
    lightbox?: any;
};

function isHTML(str: any) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
        if (c[i].nodeType == 1) return true;
    }

    return false;
}

const Modal = (props: any) => {
    const [container] = React.useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement('div');
    });

    React.useEffect(() => {
        container.classList.add('root-protal')
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return (
        <>
            {createPortal(
                props.children,
                container,
            )}
        </>
    );
}

const ImageItem = (props: any) => {
    const item: any = props.item;
    const index: any = props.index;
    const lightbox: boolean = props.lightbox;
    let imageURL: any = item.attributes.image;
    if (imageURL && !item.attributes.image.includes('https:')) {
        imageURL = processBannerImage(imageURL.replace('//', '/'), true);
    }

    const [showModal, setShowModal] = useState(false);

    return (
        <div key={`grid-item-${index}`} className={`${styles.gridItem} ${styles.imageItem} imageItem`} data-size={item.attributes.field_size ? item.attributes.field_size : ''}>
            {item.attributes.image && (
                <>
                    {lightbox ? (
                        <button className={`${styles.imageContainer} ${item.attributes.add_border ? 'hasBorder' : ''}`} onClick={() => {
                            setShowModal(true);
                        }}>
                            <img src={imageURL} alt={''} />
                            <span>Expand Image</span>
                        </button>
                    ) : item.attributes.cta_url ? (
                        <a href={item.attributes.cta_url} className={`${styles.imageContainer} imageContainer ${item.attributes.add_border ? 'hasBorder' : ''}`}>
                            <img src={imageURL} alt={''} />
                            <LinkArrow />
                        </a>
                    ) : (
                        <div className={`${styles.imageContainer} imageContainer ${item.attributes.add_border ? 'hasBorder' : ''}`}>
                            <img src={imageURL} alt={''} />
                        </div>
                    )}
                </>
            )}
            <div className={styles.textContainer}>
                {item.attributes.title && (
                    <h3>{item.attributes.title}</h3>
                )}
                {item.attributes.caption && (
                    <p>{parse(item.attributes.caption)}</p>
                )}
                {item.attributes.cta_url && (
                    <a href={item.attributes.cta_url} className={`${styles.textLink} textLink`}>{item.attributes.cta_text ? item.attributes.cta_text : 'View'}</a>
                )}
            </div>
            {lightbox && showModal && (
                <Modal>
                    <div className={`${modalStyle.dialogLayer} ${modalStyle.lightboxModal}`}>
                        <div className={`${modalStyle.dialogLayerInner}`}>
                            <div className={`${modalStyle.background}`} onClick={() => {
                                setShowModal(false);
                            }}></div>
                            <div role={'dialog'} id={'folder-dialog'} aria-modal={true} className={`${modalStyle.dialogBox} ${modalStyle.mediaContainer}`}>
                                <button className={`${modalStyle.closeBtn}`} onClick={() => {
                                    setShowModal(false);
                                }}>
                                    <span>Close</span>
                                    <Close />
                                </button>
                                <div className={modalStyle.mediaContent}>
                                    <div className={modalStyle.imageContainer}>
                                        <img src={imageURL} alt={''} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

const ImageContent = ({ content, lightbox }: Props) => {
    return (
        <>
            {content.map((item: any, index: any) => {
                if (item && item.attributes) {
                    return (
                        <ImageItem
                            item={item}
                            index={index}
                            lightbox={lightbox}
                        />
                    )
                } else {
                    return <></>
                }
            })}
        </>
    )
}

export default ImageContent;