import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

// Components
import GalleryModal from "components/feel-good-design/gallery/GalleryModal";

// Next
import Image from "next/image";

// Import Swiper React components
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y
} from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Functions
import { fetchData } from "helpers/functions";

// Styles
import styles from "styles/pages/feel-good-design/Design.module.scss";

// Images
import GalleryArrow from "images/ui-fgd-swiper.svg";

// ENV Variables
const aws_bucket: string = process.env.NEXT_PUBLIC_AWS_BUCKET as string;

const Modal = (props: any) => {
    const [container] = React.useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement('div');
    });

    React.useEffect(() => {
        container.classList.add('root-protal')
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return createPortal(
        props.children,
        container,
    );
}

const Gallery = () => {
    // Gallery Swiper
    const galleryRef: any = useRef(null);
    const galleryPrevRef: any = useRef(null);
    const galleryNextRef: any = useRef(null);

    const [gallery, setGallery] = useState<any>(null);

	const getData = async () => {
		const galleryApi: any = await fetchData(
		  `feel-good-design/gallery`
		);

		if (galleryApi.data) {
			const resArr: any = galleryApi.data;
			let imgArr: any = [];
			for(let i = 0; i < resArr.length; i++) {
				resArr.sort((a: any, b: any) => {
					return a.substring(a.lastIndexOf('/') + 1).localeCompare(b.substring(b.lastIndexOf('/') + 1))
				});
				imgArr.push(resArr[i]);
			}
			setGallery(Array.from(imgArr));
        }
    };

    useEffect(() => {
		getData();
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    // Use state for current window width
    const [width, setWidth] = useState(0);
    const [slidersPerView, setSlidesPerView] = useState<number>(4);

    // When component renders or window is resized, grab new window width
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            if (window.innerWidth > 700) {
                setSlidesPerView(4);
            } else {
                setSlidesPerView(2);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (gallery && gallery.length > 0) {
        return (
            <section id={'gallery'} className={styles.gallerySection}>
                <div className={styles.wrapper}>
                    <h2>Gallery</h2>
                </div>
                <div className={`${styles.wrapper} ${styles.galleryWrapper}`}>
                    <Swiper
                        className={styles.gallerySwiper}
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={20}
                        slidesPerView={slidersPerView}
                        ref={galleryRef}
                        navigation={{
                            prevEl: galleryPrevRef.current,
                            nextEl: galleryNextRef.current,
                        }}
                        onBeforeInit={(swiper: any) => {
                            swiper.params.navigation.prevEl = galleryPrevRef.current;
                            swiper.params.navigation.nextEl = galleryNextRef.current;
                        }}
                    >
                        {gallery && gallery.length > 0 && (
                            <button className={`swiper-button-prev ${styles.swiperSlidePrev}`} ref={galleryPrevRef}>
                                <GalleryArrow />
                                <span>View Prev Slide</span>
                            </button>
                        )}
                        {gallery.map((image: any, index: any) => {
                            const cleanURL: string = 'https://' + aws_bucket + '.s3.amazonaws.com/' + image;

                            return (
                                <SwiperSlide key={`homepage-gallery-slide-${index}`}>
                                    <div className={styles.imageContainer} onClick={() => {
                                        setActiveIndex(index);
                                        setShowModal(true);
                                    }}>
                                        <Image
                                            unoptimized={true}
                                            src={cleanURL}
                                            alt={''}
                                            layout="fill"
                                            objectFit="cover"
                                        />
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                        {gallery && gallery.length > 0 && (
                            <button className={`swiper-button-next ${styles.swiperSlideNext}`} ref={galleryNextRef}>
                                <GalleryArrow />
                                <span>View Next Slide</span>
                            </button>
                        )}
                    </Swiper>
                </div>
                {showModal && (
                    <Modal>
                        <GalleryModal
                            setShowModal={setShowModal}
                            gallery={gallery}
                            activeIndex={activeIndex}
                        />
                    </Modal>
                )}
            </section>
        )
    } else {
        return <></>
    }

}

export default Gallery;