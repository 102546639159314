import styles from "styles/pages/feel-good-design/FeelGoodDesign.module.scss";

type Props = {
    cta_text?: string;
    cta_url?: string;
    cta_type?: any;
    field_size?: string;
};

const CTAContent = ({ cta_text, cta_url, cta_type, field_size }: Props) => {
    return (
        <div className={`${styles.ctaContent} ${styles.content}`} data-size={field_size ? field_size : '100'}>
            {cta_text && cta_url && (
                <a href={cta_url} className={`${cta_type && cta_type === 'button'? `btn ${styles.btn}` : styles.textLink} secondary`}>{cta_text}</a>
            )}
        </div>
    )
}

export default CTAContent;