import React, { useState, useEffect, useRef } from "react";

// Compontents
import Divider from "components/page-builder/Divider";
import ImageContent from "components/page-builder/ImageContent";
import TextContent from "components/page-builder/TextContent";
import VideoContent from "components/page-builder/VideoContent";
import FilesContent from "components/page-builder/FilesContent";
import TableContent from "components/page-builder/TableContent";
import CarouselContent from "components/page-builder/CarouselContent";
import SliderContent from "components/page-builder/SliderContent";
import BannerContent from "components/page-builder/BannerContent";
import CTAContent from "components/page-builder/CTAContent";

type Props = {
    data?: any;
    classes?: any;
    page?: any;
    hasAnchor?: boolean;
};

const LayoutContent = ({ data, classes, page, hasAnchor = true }: Props) => {
    switch (data.layout) {
        case "banner":
            return (
                <BannerContent
                    data={data.attributes}
                />
            )
        case "section_title":
            return (
                <TextContent
                    header={data.attributes.section_title}
                    field_size={data.attributes.field_size}
                />
            )
		case "slider":
			return (
				<SliderContent
					content={data.attributes}
				/>
			)			
        case "image_group":
            if (data.attributes.images) {
                return (
                    <ImageContent
                        content={data.attributes.images}
                        lightbox={page && page === 'hm-reader' ? true : false}
                    />
                )
            } else {
                return <></>
            }
        case "paragraph":
            if (data.attributes.content) {
                return (
                    <TextContent
                        text={data.attributes.content}
                        field_size={data.attributes.field_size}
                        cta_text={data.attributes.cta_text}
                        cta_url={data.attributes.cta_url}
                    />
                )
            } else {
                return <></>
            }
        case "video":
            return (
                <VideoContent
                    embed={data.attributes.embed}
                    title={data.attributes.title}
                    thumbnail={data.attributes.thumbnail}
                    field_size={data.attributes.field_size}
                    thumbnail_border={data.attributes.add_border}
                    has_border={data.attributes.has_border}
                />
            )
        case "file_group":
            return (
                <FilesContent
                    files={data.attributes.files}
                />
            )
        case "table":
            return (
                <TableContent
                    content={data.attributes.custom_table}
                    field_size={data.attributes.field_size}
                />
            )
        case "call_to_action":
            return (
                <CTAContent
                    cta_text={data.attributes.cta_text}
                    cta_url={data.attributes.cta_url}
                    cta_type={data.attributes.type}
                    field_size={data.attributes.field_size}
                />
            )
        case "nested_call_to_action":
            return (
                <CTAContent
                    cta_text={data.attributes.cta_text}
                    cta_url={data.attributes.cta_url}
                    cta_type={data.attributes.type}
                    field_size={data.attributes.field_size}
                />
            )
        case "divider":
            return <Divider />
        case "carousel":
            return (
                <CarouselContent
                    content={data.attributes.carousel_content}
                    field_size={data.attributes.field_size}
                />
            )
        default:
            return <></>;
    }
}

export default LayoutContent;