import parse, { DOMNode, domToReact } from "html-react-parser";
import styles from "styles/pages/feel-good-design/FeelGoodDesign.module.scss";
import { slugify } from "helpers/functions";

type Props = {
    header?: string;
    text?: any;
    cta_text?: string;
    cta_url?: string;
    field_size?: string;
    hasAnchor?: boolean;
};

const TextContent = ({ header, text, cta_text, cta_url, field_size, hasAnchor = true }: Props) => {
    return (
        <div className={`${styles.textContent} textContent ${styles.content} ${field_size === '50' ? styles.columnContent : ''}`} data-size={field_size ? field_size : '100'}>
            {header && (
                <>
                    {hasAnchor ? (
                        <h2 id={slugify(header)}>{header}</h2>
                    ) : (
                        <h2>{header}</h2>
                    )}
                </>
            )}
            {text && (
                <>{parse(text)}</>
            )}
            {cta_text && cta_url && (
                <a href={cta_url} className={`btn secondary ${styles.btn}`}>{cta_text}</a>
            )}
        </div>
    )
}

export default TextContent;