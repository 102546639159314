import React, { useCallback, useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Layout from "components/global/Layout";
import parse, { DOMNode, domToReact } from "html-react-parser";
import { createPortal } from "react-dom";

// Components
import Card from "components/feel-good-design/Card";
import Gallery from "components/feel-good-design/homepage/Gallery";
import LayoutContent from "components/page-builder/LayoutContent";
import VideoModal from "components/creative-effectiveness/VideoModal";

// Functions
import { fetchData, processBannerImage, processVideoUrl } from "helpers/functions";

// Import Swiper React components
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
    Thumbs
} from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Next
import Image from "next/image";

// State
import { useRecoilState, useRecoilValue } from "recoil";
import {
    pageLoadedState,
} from "state/atoms";

// Styles
import styles from "styles/pages/feel-good-design/Design.module.scss";
import gsStyles from "styles/pages/feel-good-design/GettingStarted.module.scss";

// Images
import NavArrow from "images/ui-design-banner-nav.svg";
import GalleryArrow from "images/ui-gallery-nav.svg";
import PlayButton from "images/ui-play.svg";
import Pause from "images/ui-pause.svg";
import Play from "images/ui-play.svg";

declare const window: any;

const Modal = (props: any) => {
    const [container] = React.useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement('div');
    });

    React.useEffect(() => {
        container.classList.add('root-protal')
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return (
        <>
            {createPortal(
                props.children,
                container,
            )}
        </>
    );
}

const VideoEl = (props: any) => {
    const thumbnail: any = props.thumbnail;
    const video: any = props.video;
    const [showModal, setShowModal] = useState(false);

    return (
        <div className={styles.thumbnailContainer}>
            <button onClick={() => {
                setShowModal(true);
            }} className={styles.playButton}>
                <span>View Now</span>
            </button>
            <Image
                unoptimized={true}
                src={thumbnail ? thumbnail : ''}
                alt={''}
                layout="fill"
                objectFit="cover"
            />˝
            {showModal && (
                <Modal>
                    <VideoModal
                        title={''}
                        setShowModal={setShowModal}
                        embed={null}
                        videoURL={video}
                    />
                </Modal>
            )}
        </div>
    )
}

const BannerImageSlider = (props: any) => {
    const page: any = props.page;
    const imageSwiperRef: any = props.imageSwiperRef;
    const textSwiperRef: any = props.textSwiperRef;
    const setImageSwiper: any = props.setImageSwiper;

    return (
        <Swiper
            effect={'fade'}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={0}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            className={styles.imageSwiper}
            ref={imageSwiperRef}
            onSlideChange={(virtualIndex: any) => {
                if (textSwiperRef.current) {
                    textSwiperRef.current.swiper.slideTo(virtualIndex.activeIndex);
                }
            }}
            onSwiper={setImageSwiper}
        >
            {page.lead_content.map((content: any, index: any) => {
                return (
                    <SwiperSlide key={`lead-image-slide-${index}`}>
                        {content.attributes.file && (
                            <>
                                {content.attributes.file && content.attributes.file.includes('.mp4') ? (
                                    <VideoEl
                                        thumbnail={processBannerImage(content.attributes.thumbnail, true)}
                                        video={processBannerImage(content.attributes.file, true)}
                                    />
                                ) : (
                                    <Image
                                        unoptimized={true}
                                        src={processBannerImage(content.attributes.file, true)}
                                        alt={content.attributes.title ? content.attributes.title : ''}
                                        layout="fill"
                                        objectFit="cover"
                                    />
                                )}
                            </>
                        )}

                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

const design = () => {
    const [pageLoaded, setPageLoaded] = useRecoilState(pageLoadedState);
    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true);
        }, 125);
    }, []);

    const [page, setPage] = useState<any>(null);
    const [sections, setSections] = useState<any>(null);
    const [showModal, setShowModal] = useState(false);

    const getPage = async () => {
        let endpoint: any = 'feel-good-design/pages/design';
        const response: any = await fetchData(endpoint);
        if (!response.error) {
            const data: any = response.res.data;
            setPage(data);
        }
    };

    useEffect(() => {
        getPage();
    }, []);

    // sort temp data
    const sortSections = () => {
        let sections: any = [];
        let sectionIndex: number = 0;

        for (let i = 0; i < page.page_data.length; i++) {
            if (sections[sectionIndex] === undefined) {
                sections[sectionIndex] = {
                    'title': '',
                    'data': []
                };
            }
            if (page.page_data[i]['layout'] === 'section_title') {
                sections[sectionIndex]['title'] = page.page_data[i]['attributes']['section_title'];
            }
            sections[sectionIndex]['data'].push(page.page_data[i]);
            if (page.page_data[i]['layout'] === 'divider' && sections.length > 0) {
                sectionIndex++;
            }
        }

        if (sections.length > 0) {
            setSections(sections);
        }
    }

    useEffect(() => {
        if (page) {
            sortSections();
            console.log('page:', page);
        }
    }, [page]);

    // Banner Swiper
    const textSwiperRef: any = useRef(null);
    const imageSwiperRef: any = useRef(null);
    const navigationPrevRef: any = useRef(null);
    const navigationNextRef: any = useRef(null);
    const paginationRef: any = useRef(null);
    const [activeNum, setActiveNum] = useState<number>(1);
    const [mainSwiper, setMainSwiper] = useState<any>(null);
    const [imageSwiper, setImageSwiper] = useState<any>(null);
    const [paused, setPaused] = useState<boolean>(false);

    function togglePause() {
        paused ? setPaused(false) : setPaused(true);
    }

    // Use state for current window width
    const [width, setWidth] = useState(0);

    // When component renders or window is resized, grab new window width
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout title={`Homepage`}>
            <div className={styles.designHomepage}>
                {page && page.lead_content && page.lead_content.length > 0 && (
                    <section id={'banner'} className={styles.banner}>
                        <div className={styles.wrapper}>
                            {width <= 700 && (
                                <BannerImageSlider
                                    page={page}
                                    imageSwiperRef={imageSwiperRef}
                                    textSwiperRef={textSwiperRef}
                                    setImageSwiper={setImageSwiper}
                                />
                            )}
                            <Swiper
                                className={styles.textSwiper}
                                effect={'fade'}
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                spaceBetween={0}
                                pagination={{
                                    el: paginationRef.current,
                                    type: 'progressbar',
                                }}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                onBeforeInit={(swiper: any) => {
                                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                                    swiper.params.navigation.nextEl = navigationNextRef.current;
                                    swiper.params.pagination.el = paginationRef.current;
                                }}
                                onSlideChange={(virtualIndex: any) => {
                                    if (imageSwiperRef.current) {
                                        imageSwiperRef.current.swiper.slideTo(virtualIndex.activeIndex);
                                        setActiveNum(virtualIndex.activeIndex + 1);
                                    }
                                }}
                                ref={textSwiperRef}
                                onSwiper={setMainSwiper}
                            >
                                {page.lead_content.map((content: any, index: any) => {
                                    return (
                                        <SwiperSlide key={`lead-text-slide-${index}`}>
                                            <div className={styles.textContainer}>
                                                {content.attributes.title && (
                                                    <p className={styles.subTitle}>{content.attributes.title}</p>
                                                )}
                                                {content.attributes.description && (
                                                    <div className={styles.description}>{parse(content.attributes.description)}</div>
                                                )}
                                                {content.attributes.cta_url && (
                                                    <a href={content.attributes.cta_url} className={'btn secondary'}>{content.attributes.cta_text ? content.attributes.cta_text : content.attributes.cta_url}</a>
                                                )}
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                                {page.lead_content.length > 1 && (
                                    <div className={`navigation ${styles.navigation}`}>
                                        <button className={`swiper-button-prev ${styles.swiperSlidePrev}`} ref={navigationPrevRef}>
                                            <NavArrow />
                                            <span>View Prev Slide</span>
                                        </button>
                                        <p className={styles.slideNumber}>{activeNum < 10 ? `0${activeNum}` : activeNum}</p>
                                        <div className={`swiper-pagination ${styles.swiperPagination}`} ref={paginationRef}></div>
                                        <p className={styles.slideNumber}>{page.lead_content.length < 10 ? `0${page.lead_content.length}` : page.lead_content.length}</p>
                                        <button className={`swiper-button-next ${styles.swiperSlideNext}`} ref={navigationNextRef}>
                                            <NavArrow />
                                            <span>View Next Slide</span>
                                        </button>
                                        <button className={'pause-button'} onClick={(e: any) => {
                                            e.preventDefault();
                                            if (mainSwiper) {
                                                if (paused) {
                                                    mainSwiper.autoplay.start();
                                                    imageSwiper.autoplay.start();
                                                } else {
                                                    mainSwiper.autoplay.stop();
                                                    imageSwiper.autoplay.stop();
                                                }
                                            }
                                            togglePause();
                                        }}>
                                            {paused ? (
                                                <>
                                                    <Play />
                                                    <span>Play</span>
                                                </>
                                            ) : (
                                                <>
                                                    <Pause />
                                                    <span>Paused</span>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                )}
                            </Swiper>
                            {width > 700 && (
                                <BannerImageSlider
                                    page={page}
                                    imageSwiperRef={imageSwiperRef}
                                    textSwiperRef={textSwiperRef}
                                    setImageSwiper={setImageSwiper}
                                />
                            )}
                        </div>
                    </section>
                )}
                {sections && sections.map((section: any, index: any) => {
                    return (
                        <section className={styles.contentGrid} key={`grid-section-${index}`}>
							<div className={styles.wrapper}>
                            {section.data.map((data: any, index: any) => {
                                if (data.attributes.nested_data && data.attributes.nested_data.length > 0) {
                                    return (
										<div className={`${styles.grid} ${styles.row}`}>
											<LayoutContent data={data} />
											{data.attributes.nested_data.map((item: any, index: any) => {
												return (
													<LayoutContent
														key={`layout-content-${index}`}
														data={item}
													/>
												)
											})}
										</div>
                                    )
                                } else {
                                    return (
										<LayoutContent data={data} />
                                    )
                                }
                            })}
							</div>
                        </section>
                    )
                })}
                <Gallery />
            </div>
        </Layout>
    )
}

export default design;